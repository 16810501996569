<!-- Version: 1.0.2 -->
<template>
  <div id="fp-table-list">
    <!-- TODO: lista újratöltés gomb? -->
    <div v-if="items && filteredList">
      <b-container fluid class="eh-b-container-full-width">
        <fp-pagination
          v-if="
            hasPagination && filteredList && filteredList.length > minPerPage
          "
          :itemsPerPage.sync="perPage"
          :listSize.sync="itemsCount"
          :currentPageNumber.sync="currentPage"
        />
        <div
          v-if="hasListFilter || hasInputFilter || hasDateFilter"
          class="eh-flex-container eh-date-range-button"
        >
          <fp-input
            class="eh-table-list-input-filter"
            v-if="hasInputFilter"
            :label="$t('base.basic.filter')"
            v-model="filterInputValue"
          />
          <div
            v-if="hasDateFilter && dateFilterStartDate && dateFilterEndDate"
            class="eh-flex-container"
          >
            <div
              class="eh-date-range-button-table-list eh-date-range-button-box"
            >
              <button
                class="eh-button"
                @click="
                  showEndDatePickerCalendar = false;
                  showStartDatePickerCalendar = !showStartDatePickerCalendar;
                "
              >
                {{ $t("base.calendar.from", { date: filter.StartDate }) }}
              </button>
              <div
                v-if="showStartDatePickerCalendar"
                class="eh-date-picker-calendar-quest"
              >
                <b-calendar
                  v-model="filter.StartDate"
                  @input="setFilterInFormList"
                  :locale="$i18n.locale"
                >
                  <div style="padding: 10px" class="d-flex" dir="ltr">
                    <b-button
                      size="sm"
                      variant="outline-danger"
                      v-if="filter.StartDate"
                      @click="
                        filter.StartDate = '';
                        setFilterInFormList();
                      "
                    >
                      {{ $t("base.basic.clear") }}
                    </b-button>
                  </div>
                </b-calendar>
              </div>
            </div>
            <div
              class="eh-date-range-button-table-list eh-date-range-button-box"
            >
              <button
                class="eh-button"
                @click="
                  showStartDatePickerCalendar = false;
                  showEndDatePickerCalendar = !showEndDatePickerCalendar;
                "
              >
                {{ $t("base.calendar.to", { date: filter.EndDate }) }}
              </button>
              <div
                v-if="showEndDatePickerCalendar"
                class="eh-date-picker-calendar-quest"
              >
                <b-calendar
                  :date-disabled-fn="beforeStartDateDisabled"
                  v-model="filter.EndDate"
                  @input="setFilterInFormList"
                  :locale="$i18n.locale"
                >
                  <div style="padding: 10px" class="d-flex" dir="ltr">
                    <b-button
                      size="sm"
                      variant="outline-danger"
                      v-if="filter.EndDate"
                      @click="
                        filter.EndDate = '';
                        setFilterInFormList();
                      "
                    >
                      {{ $t("base.basic.clear") }}
                    </b-button>
                  </div>
                </b-calendar>
              </div>
            </div>
          </div>
          <!-- Egy listás kereső -->
          <fp-select
            class="eh-table-list-select-filter"
            v-if="hasListFilter"
            :items="listFilterItems"
            :valueKey="listFilterKeys ? listFilterKeys.value : null"
            :textKey="listFilterKeys ? listFilterKeys.text : null"
            v-model="filterListValue"
            :label="$t('base.basic.filter')"
          >
            <template #element="element">
              <slot name="ListFilterElement" v-bind="element">{{
                element
              }}</slot>
            </template>
          </fp-select>
          <!-- Több listás kereső -->
          <b-row v-if="hasArrayListFilter">
            <div
              v-for="(key, index) in arrayListFilterListKey"
              :key="index + '-' + key"
            >
              <fp-select
                class="col-12 col-lg-6"
                :items="arrayListFilterItems[index]"
                :valueKey="
                  arrayListFilterKeys[index]
                    ? arrayListFilterKeys[index].value
                    : null
                "
                :textKey="
                  arrayListFilterKeys[index]
                    ? arrayListFilterKeys[index].text
                    : null
                "
                v-model="filterArrayListValue[index]"
                :label="
                  arrayListFilterLabel && arrayListFilterLabel[index]
                    ? arrayListFilterLabel[index]
                    : 'Szűrő'
                "
                style="padding: 0px 5px; margin: -10px 0px"
              >
                <template #element="element">
                  <slot
                    :name="'ListArrayFilterElement' + key"
                    v-bind="element"
                    >{{ element }}</slot
                  >
                </template>
              </fp-select>
            </div>
          </b-row>
        </div>
      </b-container>
      <div>
        <b-table
          v-on="$listeners"
          :ref="refId"
          class="eh-list-table"
          :class="stickyHeader ? 'sticky-header' : ''"
          :sticky-header="stickyHeader"
          :striped="striped"
          responsive
          :items="filteredList"
          :fields="fields"
          :per-page="hasPagination ? perPage : null"
          :current-page="hasPagination ? currentPage : null"
          :sort-by.sync="itemsSortBy"
          :sort-desc.sync="itemsSortDesc"
          :sort-compare="listSorting"
          :selectable="selectable"
          :select-mode="selectMode"
          :no-select-on-click="noSelectOnClick"
          :tbody-tr-class="rowClass"
          @row-selected="onRowSelected"
          :bordered="bordered"
          sort-icon-left
          show-empty
        >
          <template
            v-for="field in fields"
            v-slot:[getDynamicHeaderSlotName(field.key)]="data"
          >
            <slot :name="'head(' + field.key + ')'" v-bind="data">
              {{ data.label }}
            </slot>
          </template>
          <template #head()="data">
            <slot :name="'head()'" v-bind="data">
              {{ data.label }}
            </slot>
          </template>
          <template
            v-for="field in fields"
            v-slot:[getDynamicCellSlotName(field.key)]="data"
          >
            <slot :name="field.key" v-bind="data">
              {{ $getChangedObjectElement(data.item, field.key) }}
            </slot>
          </template>
          <template
            v-for="field in fields"
            v-slot:[getDynamicCellSlotName(field)]="data"
          >
            <slot :name="field" v-bind="data">
              {{ data.item[field] }}
            </slot>
          </template>
          <template #empty> {{ $t("base.noData") }} </template>
          <template #row-details="row">
            <slot name="row-details" v-bind="row"></slot>
          </template>
        </b-table>
      </div>
    </div>
    <div style="text-align: center; margin: 20px" v-else>
      <b-spinner />
      <p>{{ $t("base.basic.loading") }}</p>
    </div>
  </div>
</template>
<script>
import moment from "moment";
export default {
  name: "TableList",
  props: {
    hasPagination: Boolean,
    items: Array,
    fields: Array,
    sortBy: String,
    sortDesc: Boolean,
    selectable: Boolean,
    selectMode: String, //multi/single
    isSelectAll: Boolean,
    isSelectClear: Boolean,
    refId: [String, Number],
    striped: { type: Boolean, default: true },
    tableStyle: String,
    bordered: Boolean,
    limitedDataLoad: Boolean,
    stickyHeader: Boolean,
    listSorting: Function,
    rowClass: [Function, Array, Object, String],
    noSelectOnClick: Boolean,
    //ListFilter
    hasListFilter: Boolean,
    listFilterItems: Array,
    listFilterKeys: Object, //{text,value}
    listFilterListKey: String,
    //ArrayListFilter
    hasArrayListFilter: Boolean,
    arrayListFilterItems: Array,
    arrayListFilterKeys: Array, //[{text,value}]
    arrayListFilterListKey: Array,
    arrayListFilterLabel: Array,
    //InputFilter
    hasInputFilter: Boolean,
    inputFilterKeyList: Array,
    //DateFilter
    hasDateFilter: Boolean,
    dateFilterStartDate: String,
    dateFilterEndDate: String,
  },
  data() {
    return {
      currentPage: 1,
      itemsCount: 0,
      perPage: 10,
      minPerPage: 10,
      filteredList: this.items ? JSON.parse(JSON.stringify(this.items)) : null,
      filterListValue: null,
      filterArrayListValue: this.hasArrayListFilter
        ? new Array(this.arrayListFilterListKey.length)
        : null,
      filterInputValue: null,
      showEndDatePickerCalendar: false,
      showStartDatePickerCalendar: false,
      filter: {},
      itemsSortBy: this.sortBy,
      itemsSortDesc: this.sortDesc,
    };
  },
  watch: {
    "items.length": function (input) {
      this.itemsCount = input;
    },
    items(input) {
      if (input) {
        //console.log(input.length)
        this.itemsCount = input.length;
        this.filteredList = JSON.parse(JSON.stringify(input));
        this.showEndDatePickerCalendar = false;
        this.showStartDatePickerCalendar = false;
        this.filter = {};
        this.filterListValue = null;
        this.filterInputValue = null;
      } else {
        this.filteredList = input;
      }
    },
    isSelectAll(value) {
      if (value != null) {
        value ? this.selectAll() : null;
      }
    },
    isSelectClear(value) {
      if (value != null) {
        value ? this.unselectAll() : null;
      }
    },
    filterListValue(value) {
      this.filteredList = this.$filterList(
        [this.filterInputValue, value],
        this.items,
        [this.listFilterListKey].concat(this.inputFilterKeyList)
      );
    },
    filterArrayListValue(value) {
      this.filteredList = this.$filterList(
        [this.filterInputValue].concat(value),
        this.items,
        this.inputFilterKeyList.concat(this.arrayListFilterListKey)
      );
    },
    filterInputValue(input) {
      this.filteredList = this.$filterList(
        [this.filterListValue, input].concat(this.filterArrayListValue),
        this.items,
        [this.listFilterListKey].concat(
          this.inputFilterKeyList.concat(this.arrayListFilterListKey)
        )
      );
    },
    currentPage(page) {
      if (this.limitedDataLoad) {
        const pageCount = Math.ceil(this.itemsCount / this.perPage);
        if (page == pageCount) {
          this.$emit("nextStackLoad");
        }
      }
    },
    filteredList() {
      if (this.hasPagination && this.filteredList) {
        this.itemsCount = this.filteredList.length;
        if (this.filteredList.length <= this.minPerPage) {
          this.currentPage = 1;
        }
      }
    },
    itemsSortBy(input) {
      this.$emit("update:sortBy", input);
    },
    sortBy(input) {
      this.itemsSortBy = input;
    },
    itemsSortDesc(input) {
      this.$emit("update:sortDesc", input);
    },
    sortDesc(input) {
      this.itemsSortDesc = input;
    },
  },
  methods: {
    getDynamicCellSlotName(key) {
      if (key != "row-details") {
        return `cell(${key})`;
      }
      return key;
    },
    getDynamicHeaderSlotName(key) {
      return `head(${key})`;
    },
    onRowSelected(selectedItems) {
      this.$emit("row-selected", selectedItems);
    },
    selectAll() {
      this.$refs[this.refId].selectAllRows();
      this.$emit("update:isSelectAll", false);
    },
    unselectAll() {
      this.$refs[this.refId].clearSelected();
      this.$emit("update:isSelectClear", false);
    },
    //-ig dátum szűrő naptár -tól dátum előtti napok tiltása
    beforeStartDateDisabled(ymd) {
      const day = moment(ymd);
      const start = moment(this.filter.StartDate);
      if (this.filter.StartDate) {
        return day.isBefore(start);
      }
      return false;
    },
    //dátum szerinti szűrés
    setFilterInFormList() {
      const temp = this.$filterList(
        [this.filterInputValue, this.filterListValue],
        this.items,
        [...this.inputFilterKeyList, this.listFilterListKey]
      );
      //szűrt lista tárolása
      this.filteredList = temp.filter((x) => {
        //start szűrő dátum
        const startInput = moment(this.filter.StartDate);
        //start dátum
        const start = moment(x[this.dateFilterStartDate]);
        //záró szűrő dátum
        const endInput = moment(this.filter.EndDate);
        //start dátum
        const end = moment(x[this.dateFilterEndDate]);

        //start és záró szűrő dátum van
        if (startInput.isValid() && endInput.isValid()) {
          //igaz, start és záró dátum a start és a záró szűrő dátumok között van
          return (
            start.isSameOrAfter(startInput) && end.isSameOrBefore(endInput)
          );
        } else if (startInput.isValid()) {
          //csak start szűrő dátum esetén igaz, ha a start után van
          return start.isSameOrAfter(startInput);
        } else if (endInput.isValid()) {
          //csak záró szűrő dátum esetén igaz, ha a záró előtt van
          return end.isSameOrBefore(endInput);
        }

        //ha nincs egyik szűrő dátum se akkor mindegyiket adja vissza
        return true;
      });
    },
  },
  mounted() {
    if (this.items) {
      //console.log(input.length)
      this.itemsCount = this.items.length;
      this.filteredList = JSON.parse(JSON.stringify(this.items));
      this.showEndDatePickerCalendar = false;
      this.showStartDatePickerCalendar = false;
      this.filter = {};
      this.filterListValue = null;
      this.filterInputValue = null;
    } else {
      this.filteredList = this.items;
    }
  },
};
</script>
<style>
.filter-operation {
  margin-right: 10px;
}
.date-picker-calendar-quest {
  position: absolute;
  top: 40px;
  right: 0px;
  background: white;
  border: solid 3px #627183;
  border-radius: 10px;
  text-align: center;
  z-index: 10;
}
#fp-table-list .sticky-header,
#fp-table-list .sticky-header table {
}
</style>
